import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Link } from 'gatsby';
import { Card, CardContent } from '../common/Card';

type IProps = {
  data: any;
};

export const Post: React.FC<IProps> = (props) => {
  const { title, date, category, description } = props.data.node.frontmatter;
  const { slug, categorySlug } = props.data.node.fields;

  return (
    <StyledCard>
      <CardContent>
        <div>
          <time dateTime={moment(date).format('MMMM D, YYYY')}>
            {moment(date).format('MMMM YYYY')}
          </time>
          <span />
          <span key={categorySlug}>
            <Link className="category" to={categorySlug}>
              {category}
            </Link>
          </span>
        </div>
        <h2 className="title">
          <Link to={slug}>{title}</Link>
        </h2>
        <Description>{description}</Description>
        <Link to={slug}>Read</Link>
      </CardContent>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  .category {
    font-size: 16px;
    margin-left: 20px;
  }
  .title {
    margin: 20px 0;
    > a {
      &:hover {
        border-bottom: 1px solid rgba(0, 0, 0, 0.82);
      }
      color: rgba(0, 0, 0, 0.82);
    }
  }
`;

const Description = styled.p`
  margin-bottom: 12px;
`;
